/* HomeworkGrid.css */

/* Общий контейнер домашнего задания */
.homework-container {
  padding: 20px;
  max-width: 1400px; /* Увеличено для размещения большего количества досок */
  margin: 0 auto;
}

/* Стиль для заголовка */
.homework-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
  color: #333;
}

/* Стиль для сетки статистики */
.stats-grid {
  margin-bottom: 40px;
}

/* Общий стиль для карточек статистики */
.stat-card {
  text-align: center;
  border-radius: 8px;
  color: #fff;
  padding: 12px 16px; /* Уменьшены отступы */
  height: 140px; /* Уменьшена высота для компактности */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

/* Различные цвета для разных категорий */
.stat-card.total {
  background-color: #2196f3; /* Синий для общей статистики */
}

.stat-card.success {
  background-color: #4caf50; /* Зеленый */
}

.stat-card.normal {
  background-color: #ff9800; /* Оранжевый */
}

.stat-card.bad {
  background-color: #f44336; /* Красный */
}

.stat-card.not-solved {
  background-color: #9e9e9e; /* Серый */
}

/* Стили для заголовка статистики */
.stat-card .MuiTypography-h6 {
  font-weight: bold;
  font-size: 0.95rem; /* Уменьшен размер шрифта */
  margin-bottom: 8px;
}

/* Стили для значений статистики */
.stat-card .MuiTypography-h4 {
  font-weight: bold;
  font-size: 1.3rem; /* Уменьшен размер шрифта */
}

/* Дополнительные стили для иконок */
.stat-card .MuiSvgIcon-root {
  margin-top: 8px;
  font-size: 24px; /* Уменьшен размер иконок */
}

/* Стиль для легенды (если используется) */
.legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin: 5px 15px;
}

.legend-item span {
  font-size: 0.85rem;
  color: #555;
}

/* Стили для сетки задач */
.puzzle-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px; /* Увеличен промежуток между досками */
  justify-content: center;
}

/* Стили для отдельной задачки */
.puzzle-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px; /* Уменьшен отступ */
  width: 300px; /* Увеличена ширина для размещения большего количества досок */
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.puzzle-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Стили для заголовка задачки */
.puzzle-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.puzzle-id {
  font-weight: bold;
  color: #555;
  font-size: 1rem;
}

/* Стили для иконки попыток */
.attempt-icon span {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.attempt.green {
  background-color: #4caf50;
}

.attempt.yellow {
  background-color: #ffeb3b;
}

.attempt.red {
  background-color: #f44336;
}

.attempt.neutral {
  background-color: #9e9e9e;
}

/* Стили для деталей задачки */
.puzzle-details {
  margin-top: 8px;
  color: #333;
  font-size: 0.9rem;
  text-align: left;
  width: 100%;
}

/* Стили для состояния загрузки */
.loading {
  text-align: center;
  font-size: 1.2rem;
  color: #555;
  margin-top: 50px;
}

/* Медиа-запросы для адаптивности */

/* Для очень больших экранов */
@media (min-width: 1600px) {
  .stat-card {
    width: 180px;
  }
}

/* Для больших экранов */
@media (max-width: 1200px) {
  .stat-card {
    width: 160px;
  }

  .puzzle-item {
    width: 280px; /* Уменьшена ширина для размещения большего количества досок */
  }
}

/* Для средних экранов */
@media (max-width: 992px) {
  .stat-card {
    width: 140px;
  }

  .puzzle-item {
    width: 260px;
  }
}

/* Для небольших экранов */
@media (max-width: 768px) {
  .puzzle-grid {
    gap: 25px; /* Уменьшение промежутков на малых экранах */
  }

  .stat-card {
    height: 130px; /* Уменьшена высота карточек */
  }

  .puzzle-item {
    width: 240px;
  }
}

/* Для очень маленьких экранов (мобильные устройства) */
@media (max-width: 576px) {
  .puzzle-grid {
    gap: 20px; /* Уменьшение промежутков на очень малых экранах */
  }

  .stat-card {
    width: 100%;
    height: auto;
  }

  .puzzle-item {
    width: 100%;
  }

  .puzzle-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .attempt-icon {
    margin-top: 5px;
  }
}
