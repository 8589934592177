/* Контейнер для календаря */
.calendar-container {
  max-width: 600px; /* Ограничиваем максимальную ширину */
  margin: 0 auto; /* Центрируем календарь */
  padding: 20px;
  background-color: #f9f9f9; /* Фон контейнера */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.react-tooltip {
  font-size: 14px;
  color: white;
  background-color: black;
  border-radius: 5px;
  padding: 5px 10px;
}

/* Стили для самих ячеек календаря */
.react-calendar-heatmap {
  width: 100%;
  height: auto;
}

.react-calendar-heatmap .color-empty {
  fill: #eeeeee;
}

.react-calendar-heatmap .color-scale-1 {
  fill: #d6e685;
}

.react-calendar-heatmap .color-scale-2 {
  fill: #8cc665;
}

.react-calendar-heatmap .color-scale-3 {
  fill: #44a340;
}

.react-calendar-heatmap .color-scale-4 {
  fill: #1e6823;
}

/* Дополнительные стили для улучшения внешнего вида */
.react-calendar-heatmap text {
  font-size: 10px;
}

.react-calendar-heatmap .day {
  cursor: pointer;
  transition: transform 0.2s, fill 0.2s;
}

.react-calendar-heatmap .day:hover {
  transform: scale(1.1);
  stroke: #000;
  stroke-width: 1px;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 600px) {
  .calendar-container {
    padding: 10px;
  }

  .react-calendar-heatmap text {
    font-size: 8px;
  }
}
