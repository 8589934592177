/* Стили для ссылки */
.styled-link {
    text-decoration: none; /* Убираем подчеркивание */
    color: inherit; /* Наследуем цвет текста у родителя */
  }
  
  /* Контейнер заголовка */
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2c3e50; /* Более глубокий цвет для фона */
    padding: 20px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Легкая тень */
  }
  
  /* Логотип */
  .logo {
    font-size: 1.8em;
    font-weight: bold;
    color: #ecf0f1; /* Лёгкий контраст с фоном */
    transition: color 0.3s ease;
  }
  
  .logo:hover {
    color: #f39c12; /* Изменение цвета логотипа при наведении */
  }
  
  /* Стили для кнопок */
  .button {
    background-color: #4caf50; /* Глубокий синий цвет */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .button:hover {
    background-color: #3498db; /* Немного светлее при наведении */
    transform: translateY(-2px); /* Лёгкое поднятие кнопки */
  }
  
  .button:active {
    transform: translateY(0); /* Обратное движение при клике */
  }
  
  /* Стили для выпадающего меню */
  .dropdown-menu {
    position: absolute;
    background: #fff;
    color: #333;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Увеличенная тень */
    z-index: 1000;
    top: 70px; /* Высота заголовка */
    right: 20px;
    min-width: 200px; /* Фиксированная ширина для аккуратного отображения */
  }
  
  .dropdown-menu > div {
    margin-bottom: 10px;
  }
  
  .dropdown-menu strong {
    color: #2980b9; /* Выделим текст */
  }
  
  .dropdown-menu p {
    margin: 5px 0;
  }
  