.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px; /* Установите желаемую ширину */
  text-align: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Стили для красивой кнопки */
.next-task-button {
  padding: 10px 20px; /* Отступы внутри кнопки */
  font-size: 1rem; /* Размер шрифта */
  background-color: #4caf50; /* Цвет фона */
  color: white; /* Цвет текста */
  border: none; /* Убираем границу */
  border-radius: 5px; /* Закругленные углы */
  cursor: pointer; /* Указатель при наведении курсора */
  transition: background-color 0.3s; /* Плавный переход для фона */
  margin: 10px;
}

.button {
  background-color: #4caf50; /* Зеленый фон */
  border: none;
  color: white; /* Белый текст */
  padding: 10px 20px; /* Внутренние отступы */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 5px; /* Закругленные углы */
  transition: background-color 0.3s; /* Плавный переход */
}

.button:hover {
  background-color: #45a049; /* Темнее при наведении */
}
