.notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #333;
    color: white;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-content {
    text-align: center;
}

.notification-content p {
    margin: 0 0 10px;
    font-size: 16px;
}

.notification-content button {
    background-color: #4CAF50; /* Зеленый цвет, приятный для глаз */
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.1s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Легкая тень для объема */
}

.notification-content button:hover {
    background-color: #45a049; /* Чуть темнее при наведении */
    transform: scale(1.05); /* Небольшое увеличение при наведении */
}

.notification-content button:active {
    background-color: #388e3c; /* Темный зеленый при клике */
    transform: scale(0.95); /* Легкое уменьшение при клике */
}