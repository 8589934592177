/* ChessBoard.css */

/* Общие стили для всех устройств */
.parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.board {
  width: 500px;
  margin-right: 20px;
}

.turn-info {
  margin-bottom: 10px;
}

.turn-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

.task-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.task {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.next-task-button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.next-task-button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .parent {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .board {
    width: 100%;
    max-width: 350px;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .turn-info {
    font-size: 1em;
  }

  .turn-indicator {
    width: 15px;
    height: 15px;
  }

  .task-container {
    width: 100%;
    max-width: 350px;
    align-items: center;
  }

  .task {
    font-size: 1em;
    text-align: center;
  }

  .next-task-button {
    width: 100%;
    max-width: 350px;
    padding: 12px 0;
    font-size: 1.1em;
  }
}

@media (max-width: 480px) {
  .board {
    max-width: 300px;
  }

  .task-container {
    max-width: 300px;
  }

  .next-task-button {
    font-size: 1em;
    padding: 10px 0;
  }
}
