/* Стиль для контейнера */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f0f0f0;
  min-height: 100vh;
}

/* Стиль для заголовка */
.header {
  font-size: 2.5em;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

/* Карточки функций */
.card-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 20px;
  margin: 20px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.card h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #2c3e50;
}

.card p {
  font-size: 1em;
  color: #7f8c8d;
}

/* Кнопки */
.login-button-container {
  margin-top: 20px;
}

.login-button {
  background-color: #2980b9;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 8px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #3498db;
}
