.add-modal {
  position: fixed;
  top: 100px; /* Увеличиваем отступ, чтобы окно не перекрывало заголовок */
  right: 20px; /* Расположение по горизонтали */
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 250px; /* Задаем ширину */
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-modal h2 {
  margin: 0 0 10px; /* Стили заголовка */
}

button {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.close-button {
  background-color: #dc3545;
  margin-top: 5px;
}

.close-button:hover {
  background-color: #c82333;
}
