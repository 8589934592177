/* Контейнер для всех тем */
.themes-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 6 столбцов */
  gap: 20px; /* Отступы между карточками */
  margin: 20px;
  padding: 10px;
  justify-items: center;
}

/* Медиазапрос для уменьшения количества столбцов на меньших экранах */
@media (max-width: 1200px) {
  .themes-container {
    grid-template-columns: repeat(4, 1fr); /* 4 карточки на экранах до 1200px */
  }
}

@media (max-width: 800px) {
  .themes-container {
    grid-template-columns: repeat(2, 1fr); /* 2 карточки на экранах до 800px */
  }
}

@media (max-width: 500px) {
  .themes-container {
    grid-template-columns: 1fr; /* 1 карточка на экранах до 500px */
  }
}

/* Карточки для тем */
.theme-card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 150px; /* Фиксированная ширина карточек */
  height: 100px; /* Фиксированная высота карточек */
  background-color: #f0f0f0; /* Светло-серый фон */
  color: #333; /* Темный текст */
  text-decoration: none; /* Убираем подчеркивание ссылок */
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
  border-radius: 8px; /* Округленные углы */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Легкая тень для глубины */
  transition: transform 0.3s, background-color 0.3s;
  overflow-wrap: break-word; /* Перенос слов на новую строку */
  text-overflow: ellipsis; /* Добавляет многоточие, если текст не вмещается */
}

/* Автоматическое уменьшение шрифта при большом количестве текста */
.theme-card span {
  display: block;
  font-size: clamp(
    14px,
    2vw,
    18px
  ); /* Уменьшение шрифта в зависимости от длины */
}

/* Эффект при наведении */
.theme-card:hover {
  background-color: #e0e0e0; /* Чуть темнее при наведении */
  transform: translateY(-5px); /* Легкий подъем карточки при наведении */
}
.theme-wrapper {
  position: relative; /* Чтобы выпадающий список был привязан к теме */
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Список появится прямо под карточкой */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  width: 180px;
  z-index: 10;
}

.dropdown-item {
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
.themes-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 карточек в строке */
  gap: 30px; /* Увеличенный отступ между карточками */
  margin-bottom: 20px; /* Отступ между строками */
  justify-items: center; /* Центровка карточек по горизонтали */
  padding: 10px; /* Отступы внутри контейнера */
}
.theme-wrapper {
  margin: 5px; /* Отступ между карточками */
}
.global-theme-title {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0; /* Отступы сверху и снизу для равенства с карточками */
  text-align: center; /* Центровка заголовка */
  padding: 10px; /* Добавление отступов для равенства с карточками */
  background-color: #f0f0f0; /* Легкий фон для выделения */
  border-radius: 8px; /* Округленные углы, чтобы соответствовать карточкам */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Тень для создания глубины */
}
